@use '@angular/material' as mat;

@include mat.core();

$dark-primary: mat.define-palette(mat.$green-palette);
$dark-accent: mat.define-palette(mat.$amber-palette, A400, A100, A700);
$dark-warn: mat.define-palette(mat.$red-palette);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

@include mat.all-component-themes($dark-theme);

// light theme
.light-theme {
  color: white;
  $primary: mat.define-palette(mat.$amber-palette);
  $accent: mat.define-palette(mat.$yellow-palette, A200, A100, A400);
  $warn: mat.define-palette(mat.$red-palette);
  $light-theme: mat.define-light-theme($primary, $accent, $warn);
  
  @include mat.all-component-themes($light-theme);
}


$custom-typography: mat.define-typography-config(
  $font-family: 'Jura, monospace',
);
// Override the typography in the core CSS.
@include mat.core($custom-typography);

.mat-card-header-text {
  height: auto;
  margin: 0 !important;
}