
@import './app/shared/styles/theme';
@import '../../../node_modules/snazzy-info-window/dist/snazzy-info-window.scss';
/* You can add global styles to this file, and also import other style files */

html, body {
  margin: 0; 
  min-height: 100%;
  height: 100%;
}

// body { font-family: Roboto, "Helvetica Neue", sans-serif; }

.icon-1-3-x {
  transform: scale(1.3) !important;
  line-height: 115%;
}
.icon-1-5-x {
  transform: scale(1.5) !important;
  line-height: 115%;
}
.icon-2-0-x {
  transform: scale(2) !important;
  line-height: 115%;
}
.icon-3-0-x {
  transform: scale(3) !important;
  line-height: 115%;
}
.font-1-0-rem {
  font-size: 1rem !important;
  line-height: 115%;
}
.font-1-3-rem {
  font-size: 1.3rem !important;
  line-height: 115%;
}
.font-1-5-rem {
  font-size: 1.5rem !important;
  line-height: 115%;
}
.font-2-0-rem {
  font-size: 2rem !important;
  line-height: 115%;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.gm-style-iw .gm-style-iw-c {
  background-color: black !important;
}